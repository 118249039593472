import { getIcon } from './assets';
import { getUser } from './user';
import { getConfig } from './config';
import { tracking } from '../constants/tracking';

export {
  linkTemplate,
  linkFilter,
  linkPanelFilter,
  linkDecorator
};

/**
 * link template
 * @param {object} link object
 * @param {string} classes 
 * @param {string} icon 
 */
const linkTemplate = (link, classes, icon) => `
  <a 
    class="${classes} ${tracking.class}"
    ${linkDecorator(link)}
  >
    ${link.title}
    ${link.icon ? getIcon(link.icon) : ''}
    ${icon ? getIcon(icon) : ''}
    ${link.tag ? `<div class="sln-tag">${link.tag}</div>` : ''}
  </a>
`;

/** common attributes for links */
const linkDecorator = (link) => {
  if(!link) {
    return '';
  }
  return `
    ${link.href ? `href="${link.href}"` : ''}
    ${link.title && !link.hideTitle ? `title="${link.title}"` : ''}
    ${link.target ? `target="${link.target}"` : ''}
    ${link.rel ? `rel="${link.rel}"` : ''}
    ${link.dataId ? `data-id="${link.dataId}"` : ''}
    ${link.attributes ? link.attributes.map(attr => `${attr.name}="${attr.value}"`).join('') : ''}
  `;
};

/** decide if we show a link or not */
const linkFilter = (link) => {
  const roles = getConfig().roles;

  if(link.roles && typeof link.roles === 'string') {
    if(Array.isArray(roles)) {
      return roles.includes(link.roles);
    }
    return link.roles === roles;
  }
  
  if(link.roles && Array.isArray(link.roles)) {
    let found = false;

    if(Array.isArray(roles)) {
      roles.forEach(role => {
        link.roles.forEach(r => {
          if(r.name === role) {
            found = true;
          }
        });
      });
      return found;
    }

    link.roles.forEach(r => {
      if(r.name === roles) {
        found = true;
      }
    });

    return found;
  }

  // link has private flag indicating ONLY logged in users should see this
  if (link.private) {
    return getUser().authenticated;
  }
  // link has public flag indication ONLY public users should see this
  if (link.public) {
    return !getUser().authenticated;
  }

  return true;
};

/** decide if we show a link or not specifically for the panel */
const linkPanelFilter = link => {
  const roles = getConfig().roles;

  if(link.roles && typeof link.roles === 'string') {
    if(Array.isArray(roles)) {
      return roles.includes(link.roles);
    }
    return link.roles === roles;
  }
  
  if(link.roles && Array.isArray(link.roles)) {
    let found = false;

    if(Array.isArray(roles)) {
      roles.forEach(role => {
        link.roles.forEach(r => {
          if(r.name === role) {
            found = true;
          }
        });
      });
      return found;
    }

    link.roles.forEach(r => {
      if(r.name === roles) {
        found = true;
      }
    });

    return found;
  }
  
  // link has private flag indicating ONLY logged in users should see this
  if (link.private && (link.type === 'link' || link.type === 'dropdown')) {
    return getUser().authenticated;
  }
  // link has public flag indication ONLY public users should see this
  if (link.public && (link.type === 'link' || link.type === 'dropdown')) {
    return !getUser().authenticated;
  }

  return link.type === 'link' || link.type === 'dropdown';
};

