import { createEl } from '../utils/helpers';
import { getIcon } from '../utils/assets';
import { getConfig } from '../utils/config';

import { linkFilter } from '../utils/link';
import { tracking } from '../constants/tracking';
import { getLanguage } from '../utils/language';
import { renderProp, has } from '../utils/helpers';

import { labels } from '../utils/labels';
import {
  panelWrapperTemplate,
  navigationTemplate,
  wrapperTemplate,
} from './panel.tmpl';

export { template, submenuTemplate, logoTemplate };

/**
 * header logo template
 */
const logoTemplate = (classes = 'sln-header__logo') => {
  const logo = getConfig().logo;

  return createEl('a', {
    ...logo,
    classes: `${classes} ${tracking.class}`,
    html: `<img src="${logo.img.src}" alt="${logo.img.alt}">`,
  });
};

const submenuTemplate = (
  links,
  type,
  classes = 'sln-header__menu',
  align = 'left'
) => {
  const menu = createEl('div', {
    classes: `${classes} ${classes}--${type} ${classes}--${align}`,
    html: `
      <button class="${classes}__close">
        ${renderProp(labels, 'back', 'Back')} ${getIcon('close')}
      </button>
    `,
  });

  links.filter(linkFilter).forEach((link) => {
    const linkClass = `${classes}__link ${
      type === 'language' && getLanguage() === link.dataId
        ? `${classes}__link--active`
        : ''
    }`;
    const a = createEl('a', {
      classes: linkClass,
      ...link,
      html: link.title,
    });

    if (link.subheading) {
      const subheading = createEl('div', {
        classes: `${classes}__link ${classes}__link--subheading`,
        html: link.subheading,
      });
      menu.appendChild(subheading);
    }

    menu.appendChild(a);

    if (link.hr) {
      const hr = createEl('hr', { classes: 'sln-header__hr' });
      menu.appendChild(hr);
    }
  });

  return menu;
};

const primaryLinks = (links) => {
  const primary = [];

  links.filter(linkFilter).forEach((link) => {
    const item = createEl('div', {
      classes: `sln-header__primary sln-header__primary--${link.type} ${
        link.icon ? 'sln-header__primary--icon' : ''
      } ${
        link.push
          ? link.push
              .split(',')
              .map((size) => `sln-header__primary--push-${size} `)
              .join('')
          : ''
      }`,
    });
    const wrapper = createEl('div', {
      classes: 'sln-header__primary__wrapper',
    });

    let html = '';
    const classes = ['sln-header__primary__link', tracking.class];

    if (link.title && (link.type === 'link' || link.type === 'dropdown')) {
      html = link.title;
    }

    if (link.type === 'language') {
      html = getLanguage().toUpperCase();
    }

    if (link.lefticon) {
      html = getIcon(link.lefticon).replace('class="sln-icon"', 'class="sln-icon-left"') + html;
    }

    if (link.submenu) {
      html += getIcon('caret');
      classes.push('sln-header__primary__link--dropdown');
    }

    if (link.icon && !link.submenu) {
      html += getIcon(link.icon);
      classes.push('sln-header__primary__link--icon');
    }

    const a = createEl('a', { classes: classes.join(' '), ...link, html });

    wrapper.appendChild(a);

    if (link.submenu) {
      const submenu = submenuTemplate(
        link.submenu.links,
        link.type,
        'sln-header__menu',
        link.submenu.align
      );
      wrapper.appendChild(submenu);
    }

    item.appendChild(wrapper);
    primary.push(item);
  });
  return primary;
};

const template = (data) => {
  const header = createEl('div', {
    html: `<div class="sln-header__mask"></div>
    <div class="sln-header__container"></div>`,
  });
  const links = has(data, 'primary.links') ? data.primary.links : [];
  const primary = primaryLinks(links);
  const container = header.querySelector('.sln-header__container');
  const navigation = panelWrapperTemplate(
    {
      navigation: data.navigation,
      primary: links,
      id: 'navigation',
    },
    navigationTemplate
  );

  container.appendChild(logoTemplate());
  primary.forEach((el) => {
    container.appendChild(el);
  });

  header.appendChild(navigation);

  if (data.panels) {
    data.panels.forEach((panel) => {
      const panelEl = wrapperTemplate(panel.id);
      
      if(panel.labels) {
        const labels = createEl('div', {
          classes: 'sln-header__labels'
        });
        panel.labels.forEach(label => {
          const div = createEl('div', {
            ...label,
            classes: 'sln-header__labels__label',
            html: `${label.label}<div class="sln-header__labels__label__value">${label.value}</div>`
          });

          if(label.hr) {
            const hr = createEl('hr', {
              classes: 'sln-header__hr'
            });
            div.appendChild(hr);
          }

          labels.appendChild(div);
        });

        panelEl.appendChild(labels);
      }

      const links = createEl('div', {
        classes: 'sln-header__links'
      });
      panel.links.forEach(link => {
        const a = createEl('a', {
          ...link,
          classes: 'sln-header__links__link',
          html: link.title
        });

        if(link.hr) {
          const hr = createEl('hr', {
            classes: 'sln-header__hr'
          });
          a.appendChild(hr);
        }

        links.appendChild(a);
      });

      panelEl.appendChild(links);

      header.appendChild(panelEl);
    });
  }

  return header;
};
