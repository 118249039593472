import { getIcon } from '../utils/assets';
import { submenuTemplate } from './header.tmpl';
import { getLanguage } from '../utils/language';
import { promoTemplate } from './promo.tmpl';
import { labels } from '../utils/labels';
import { renderProp, createEl, has } from '../utils/helpers';

import { linkFilter, linkPanelFilter } from '../utils/link';

export { panelWrapperTemplate, promoTemplate, navigationTemplate, wrapperTemplate };

/** language template for the navigation panel (mobile / tablet only) */
const languageTemplate = (data) => {
  const languages = data.find((item) => item.type === 'language');

  if (languages) {
    const button = createEl('div', {
      classes: 'sln-header__panel__language sln-header__panel__menu__toggle',
      html: `${getLanguage().toUpperCase()} ${getIcon('caret')}`,
    });
    const submenu = submenuTemplate(
      languages.submenu.links,
      'language',
      'sln-header__panel__menu'
    );

    return [button, submenu];
  }

  return [];
};

/** primary text and dropdown links template for the navigation panel (mobile / tablet only) */
const primaryTemplate = (links) => {
  const primary = createEl('div', {
    classes: 'sln-header__panel__primary',
  });

  links.filter(linkPanelFilter).forEach((link) => {
    let html = '';
    const classes = [
      'sln-header__panel__primary__link',
      `sln-header__panel__primary__link--${link.type}`,
      'sln-header__panel__menu__toggle'
    ];

    if (link.title && (link.type === 'link' || link.type === 'dropdown')) {
      html = link.title;
    }

    if (link.type === 'dropdown') {
      html += getIcon('chevronRight');
    }

    if (link.icon && link.type !== 'dropdown') {
      html += getIcon(link.icon);
    }

    if (link.lefticon) {
      html = getIcon(link.lefticon).replace('class="sln-icon"', 'class="sln-icon-left"') + html;
    }

    const a = createEl('a', { classes: classes.join(' '), html, ...link });
    primary.appendChild(a);

    if (link.submenu) {
      const menu = submenuTemplate(
        link.submenu.links,
        link.type,
        'sln-header__panel__menu'
      );
      primary.appendChild(menu);
    }
  });
  return primary;
};

const navigationTemplate = (data) => {
  const columns = createEl('div', {
    classes: 'sln-header__panel__columns',
  });

  data.navigation.columns.forEach((col) => {
    const column = createEl('div', {
      classes: 'sln-header__panel__columns__column',
    });

    col.lists.forEach((list, index) => {
      const l = createEl('div', {
        classes: `sln-header__panel__list sln-delay sln-delay--${index}`,
      });

      list.links.filter(linkFilter).forEach((link) => {
        const a = createEl('a', {
          classes: `sln-header__panel__link ${
            link.heading ? 'sln-header__panel__link--heading' : ''
          }`,
          html: link.title,
          ...link,
        });

        l.appendChild(a);
      });

      column.appendChild(l);
    });

    columns.appendChild(column);
  });

  return columns;
};

const wrapperTemplate = (id) => {
  return createEl('div', {
    classes: `sln-header__panel sln-header__panel--${id}`,
    id: `sln-panel__${id}`,
    html: `
    <button class="sln-header__panel__close">
      ${renderProp(labels, 'close', 'Close')} ${getIcon()}
    </button>
  `,
  });
};

const panelWrapperTemplate = (data, template) => {
  const wrapper = wrapperTemplate(data.id);

  const primary = primaryTemplate(data.primary);
  const language = languageTemplate(data.primary);

  wrapper.appendChild(primary);
  language.forEach((el) => {
    wrapper.appendChild(el);
  });

  if (typeof data.navigation !== 'undefined') {
    wrapper.appendChild(template(data));

    if (has(data, 'navigation.promo')) {
      const promo = promoTemplate(data.navigation.promo);
      wrapper.appendChild(promo);
    }
  }

  return wrapper;
};
